<template>
  <!--
    Used to display an installations devices as small as possible.
  -->
  <div class="deviceListMini">
    <LoadingPlaceholder v-if="loading" />
    
    <template v-else>
      <template v-if="devices && installationId">
        <template v-for="(device, index) in devices">
          <span
            v-if="device.uuid === deviceId"
            :key="`deviceListMini-deviceRouterLink-${ index }`"
            class="float-left border rounded p-2 deviceItem mr-2 active mb-1 mt-1"
          >
            <font-awesome-icon
              :class="`${ deviceIconColorByVpnIpAddress(device.vpnIpAddress) } mr-2`"
              :icon="`${ deviceIconByType(device.deviceType) }`"
            />
            <strong>{{ device.name }}</strong>
          </span>
        
          <router-link
            v-else
            :key="`deviceListMini-deviceRouterLink-${ index }`"
            :to="deviceDetailLinkByUuid(device.uuid)"
            class="float-left border rounded p-2 deviceItem mr-2 mb-1 mt-1"
          >
            <font-awesome-icon
              :class="`${ deviceIconColorByVpnIpAddress(device.vpnIpAddress) } mr-2`"
              :icon="`${ deviceIconByType(device.deviceType) }`"
            />
            <strong>{{ device.name }}</strong>
          </router-link>
        </template>

        <div class="clearfix" />
      </template>
      <p v-else>
        {{ $t('noDataAvailable') }}
      </p>
    </template>
  </div>
</template>

<script>
export default {
  name: 'DeviceListMini',
  props: {
    installationId: {
      type: String,
      required: false,
      default: ""
    },
    deviceId: {
      type: String,
      required: false,
      default () {
        return '';
      }
    }
  },
  data () {
    return {
      loading: true,
      devices: null
    }
  },
  created () {
    this.getDevices();
  },
  methods: {
//#region Helper
    computedVpnText (ipAddress) {
      if (ipAddress == null) {
        return 'unavailable';
      }
      if (ipAddress.length == 0) {
        return 'unavailable';
      }
      return ipAddress;
    },
    computedVpnColor (ipAddress) {
      if (ipAddress == null) {
        return 'red';
      }
      if (ipAddress.length == 0) {
        return 'red';
      }
        return 'green';
    },
    deviceIconColorByVpnIpAddress (vpnIpAddress) {
      let hasVpnReturnValue = 'green';
      let hasNoVpnReturnValue = 'red';
      if (vpnIpAddress == null) {
        return hasNoVpnReturnValue;
      }
      if (vpnIpAddress.length == null) {
        return hasNoVpnReturnValue;
      }
      if (vpnIpAddress.length == 0) {
        return hasNoVpnReturnValue;
      }
      return hasVpnReturnValue;
    },
    deviceDetailLinkByUuid (uuid) {
      return { path: `/installation/${ this.installationId }/devices/device/${ uuid }` };
    },
    shortenDeviceType (deviceType) {
      let indexOfMinus = deviceType.indexOf("-");
      if (indexOfMinus < 0) {
        return deviceType;
      }
      let shortenedDeviceType = deviceType.substring(0, indexOfMinus);
      return shortenedDeviceType;
    },
    showIfInactive (vpnIpAddress) {
      if (vpnIpAddress == null) {
        return true;
      }
      if (vpnIpAddress.length == null) {
        return true;
      }
      if (vpnIpAddress.length == 0) {
        return true;
      }
      return false;
    },
    deviceIconByType (deviceType) {
      if (deviceType == null) {
        return;
      }
      if (deviceType.length == null) {
        return;
      }
      if (deviceType.length == 0) {
        return;
      }
      switch (this.shortenDeviceType(deviceType)) {
        case 'odysseus':
          return 'microchip'; 
        case 'x86':
          return 'server'; 
        case 'mikrotik':
          return 'wifi'; 
        case 'odroid':
          return 'microchip'; 
        case 'webrelay':
          return 'charging-station'; 
        default:
          return 'network-wired'; 
      }
    },
//#endregion

//#region API-clls
    getDevices () {
      this.loading = true;
      this.axios.get(`/CentralDeviceManagement/GetFromInstallationId?installationId=${ this.installationId }`)
        .then((response) => {
          if (response == null) {
            return;
          }
          if (response.data == null) {
            return;
          }
          this.devices = response.data;
        })
        .finally(() => {
          this.loading = false;
        });
    }
//#endregion
  }
}
</script>

<style scoped>
.deviceItem {
  color: #3e3e4d;
}
.deviceItem.active {
  background: lightgray;
}
</style>
